import React from 'react'
import SignUp from '../../components/SignUp'

const Page = ({ location }) => {
    return (
        <SignUp
            language="en"
            location={location}
        />
    )
}

export default Page
